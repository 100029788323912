.compute_instance_billing_head {
  width: 16.6% !important;
  min-width: 120px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
}

.compute_instance_billing_body {
  width: 16.6% !important;
  font-size: small;
  font-weight: 500;
  min-width: 120px;
  padding-top: 20px;
  padding-left: 10px;
  padding-right: 10px;
}

.invoice_summary_head {
  width: 20% !important;
  min-width: 120px;
}

.invoice_summary_body {
  width: 20% !important;
  min-width: 120px;
  margin-top: 0.5rem;
  font-size: 0.75rem;
  color: rgb(75, 85, 99);
  font-weight: 600;
  text-align: right !important;
  align-items: center;
}

.invoice_new_member_head {
  width: 25% !important;
  min-width: 120px;
}

.invoice_new_member_body {
  width: 25% !important;
  min-width: 120px;
  margin-top: 0.5rem;
  font-size: 0.75rem;
  color: rgb(75, 85, 99);
  font-weight: 600;
  align-items: center;
  overflow-wrap: break-all;
}

.invoice_bonus_head {
  width: 20% !important;
  min-width: 120px;
}

.invoice_bonus_body {
  width: 20% !important;
  min-width: 120px;
  margin-top: 0.5rem;
  font-size: 0.75rem;
  color: rgb(75, 85, 99);
  font-weight: 600;
  text-align: right !important;
  align-items: center;
}

.invoice_chosen_token {
  min-width: 150px !important;
}

.invoice_first_row {
  text-align: left !important;
}

.pending_invite_status {
  width: 35% !important;
  min-width: 150px;
}

.pending_invite_email {
  width: 35% !important;
  min-width: 230px;
}

.pending_invite_invited_on {
  width: 23% !important;
  min-width: 120px;
}

.pending_invite_action {
  width: 7% !important;
  min-width: 90px;
}

.deployment_card_tabs {
  width: 25% !important;
  min-width: 150px;
  max-width: 150px;
}

.member_table_username {
  width: 17% !important;
  min-width: 120px;
}

.member_table_profile {
  width: 25% !important;
  min-width: 200px;
}

.member_table_email {
  width: 23% !important;
  min-width: 180px;
}

.member_table_role {
  width: 11% !important;
  min-width: 130px;
}

.member_table_date {
  width: 12% !important;
  min-width: 120px;
}

.member_table_action {
  width: 8% !important;
  min-width: 90px;
}

.domain_table_container {
  overflow-x: auto !important;
  max-width: 90vw;
  .compute_domain_card_type {
    width: 25% !important;
    min-width: 120px;
  }
  .domain_card_type {
    width: 20% !important;
    min-width: 120px;
  }
  .domain_card_name {
    width: 30% !important;
    min-width: 150px;
  }

  .domain_card_value {
    width: 50% !important;
    min-width: 200px;
  }
}

.bonus_table_container {
  overflow-x: auto !important;
  max-width: 90vw;
  .bonus_details {
    width: 30%;
    min-width: 250px;
  }
  .bonus_quantity {
    width: 15%;
    min-width: 150px;
  }
  .bonus_amount {
    width: 15%;
    min-width: 150px;
  }
  .bonus_expiration {
    width: 25%;
    min-width: 150px;
  }
  .bonus_remove {
    width: 5%;
    min-width: 50px;
  }
}

.table_con {
  width: 76vw !important;
  overflow-x: scroll;
  padding: 20px;
  padding-bottom: 0px !important;
  table {
    width: 100%;
    min-width: 500px;
    thead {
      tr {
        td,
        th {
          padding-top: 8px;
          padding-bottom: 20px;
          min-width: 120px !important;
        }
      }
    }
    tbody {
      tr {
        td,
        th {
          padding-top: 8px;
          padding-bottom: 8px;
          min-width: 120px !important;
        }
      }
    }
  }
}

@media (min-width: 1024px) {
  .table_con {
    width: 100% !important;
  }
}

.instance_table_internal {
  width: 25% !important;
  min-width: 120px;
  padding-left: 2rem /* 32px */;
  padding-right: 2rem /* 32px */;
}

.instance_table_external {
  width: 25% !important;
  min-width: 120px;
  padding-left: 2rem /* 32px */;
  padding-right: 2rem /* 32px */;
}

.instance_table_url {
  width: 50% !important;
  min-width: 240px;
  padding-left: 2rem /* 32px */;
  padding-right: 2rem /* 32px */;
}

.right__up__btn {
  cursor: pointer;
  &:hover {
    rect {
      fill: #c0d4f6;
    }
    path {
      stroke: #000000;
    }
  }
}

.copy__icon {
  cursor: pointer;
  &:hover {
    path {
      stroke: var(--primary-black-text-light);
    }
  }
}

.invoice__table {
  thead {
    tr {
      th:nth-child(1) {
        width: 30% !important;
      }
    }
  }
}
