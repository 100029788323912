.overview__outer__con {
  min-height: calc(100vh - 168px);
}
.no__project__container {
  height: 500px;
  width: 95%;
  border: 1px solid #e4e8f5;
  box-sizing: border-box;
  border-radius: 8px;
  max-width: 1400px;
  .no__project__image {
    height: 150px;
  }
  h1 {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 18px;
    color: #4977f9;
    margin-top: 20px;
  }
  h5 {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 18px;
    color: rgba(0, 0, 0, 0.7);
    margin-top: 10px;
    margin-bottom: 50px;
  }
}

@media (max-width: 720px) {
  .org__header {
    width: 100%;
    display: flex;
    flex-direction: row;
    .org__inner__header {
      width: 95%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: left;
      max-width: 1400px;
      gap: 16px;
    }
  }
}

.org__header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .org__inner__header {
    width: 95%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1400px;
    .org__name {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      color: var(--primary-black-text);
    }
    .org__total__members,
    .org__total__projects {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 12px;

      color: var(--primary-black-text);
    }
    .line {
      width: 6px;
      opacity: 0.32;
      border-top: 1px solid var(--primary-black-text);
      transform: rotate(90deg);
    }
  }
}
