.modal__outer__con {
  z-index: 8888;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--modal-overlay);
  position: fixed;
  transition: 50ms cubic-bezier(0.18, 0.89, 0.32, 1.28);

  @media (max-width: 640px) {
    .modal__inner__con {
      width: 95vw !important;
    }
  }

  .modal__inner__content {
    width: 700px;
    background: var(--modal-bg);
    animation: fadein 0.1s forwards;
    overflow: visible;
    border-radius: 0.75rem;
  }
  .modal__inner__con {
    width: 700px;
    background: var(--modal-bg);
    animation: fadein 0.1s forwards;
    border: 0.5px solid var(--base-border-color);
    box-shadow: 0px 10px 28px rgba(38, 42, 49, 0.2);
    border-radius: 20px;
    padding: 34px 24px;
    overflow: visible;
    .modal__inner__heading {
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 28px;
      color: var(--primary-black-text);
    }
    .inner__top__info {
      width: 100%;
      background: var(--modal-top-info);
      border-radius: 12px;
      display: flex;
      align-items: center;
      padding: 0px 24px;
      margin-bottom: 14px;
      img {
        margin-right: 20px;
      }
      p {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        color: var(--modal-top-info-text);
        padding: 15px 0px;
      }
    }
    h5 {
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: -0.08px;
      color: var(--modal-input-label);
      margin-bottom: 10px;
    }
    .button__con {
      display: flex;
      gap: 20px;
      margin-top: 24px;
      .send__button {
        width: 129px;
        height: 36px;
        background: #4977f9;
        box-shadow: 0px 4px 6px rgba(64, 112, 244, 0.16);
        border-radius: 6px;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        color: #ffffff;
        margin-right: 16px;

        &:hover {
          background: darken($color: #4977f9, $amount: 10);
        }

        &:disabled {
          background: lighten($color: #4977f9, $amount: 10);
          cursor: not-allowed;
        }
      }
      .cancel__button {
        width: 129px;
        height: 36px;
        left: 227px;
        top: 325px;
        background: #f4f7ff;
        border: 1px solid #4977f9;
        box-shadow: 0px 1px 4px rgba(73, 119, 249, 0.06);
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        color: #4977f9;

        &:hover {
          border-color: darken($color: #4977f9, $amount: 10);
          color: darken($color: #4977f9, $amount: 10);
        }

        &:disabled {
          border-color: lighten($color: #4977f9, $amount: 10);
          color: lighten($color: #4977f9, $amount: 10);
          cursor: not-allowed;
        }
      }
      .delete__button {
        width: 129px;
        height: 36px;
        background: #d92d20;
        box-shadow: 0px 4px 6px rgba(64, 112, 244, 0.16);
        border-radius: 6px;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        color: #ffffff;
        margin-right: 16px;

        &:hover {
          background: darken($color: #d92d20, $amount: 10);
        }

        &:disabled {
          background: lighten($color: #d92d20, $amount: 10);
          cursor: not-allowed;
        }
      }
      .cancel__delete__button {
        width: 129px;
        height: 36px;
        left: 227px;
        top: 325px;
        background: #f4f7ff;
        border: 1px solid #d92d20;
        box-shadow: 0px 1px 4px rgba(73, 119, 249, 0.06);
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        color: #d92d20;

        &:hover {
          border-color: darken($color: #d92d20, $amount: 10);
          color: darken($color: #d92d20, $amount: 10);
        }

        &:disabled {
          border-color: lighten($color: #d92d20, $amount: 10);
          color: lighten($color: #d92d20, $amount: 10);
          cursor: not-allowed;
        }
      }
    }
    .modal__inner__con__small {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding-top: 30px;
      h1 {
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 28px;
        color: var(--primary-black-text);
        margin-bottom: 24px;
        margin-top: 30px;
      }
    }
  }
  // SOLANA PAY MODAL
  .modal__inner__con__solana {
    width: 749px;
    background: var(--modal-bg);
    animation: fadein 0.1s forwards;
    border: 0.5px solid var(--base-border-color);
    box-shadow: 0px 10px 28px rgba(38, 42, 49, 0.2);
    border-radius: 20px;
    padding: 34px 24px;
    .modal__inner__con__small {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: left;
      h1 {
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 28px;
        color: var(--primary-black-text);
        margin-bottom: 14px;
      }
      h2 {
        font-style: normal;
        font-size: 14;
        font-weight: 400;
        line-height: 28px;
        color: var(--primary-black-text);
        margin-bottom: 14px;
      }
    }
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.no__padding {
  padding: 0 !important;
}

.inner__disclosure {
  width: 100%;
  background: lighten($color: #b2d0fd, $amount: 10%);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 12px;
  margin-top: 15px;
  span {
    font-size: medium;
    font-weight: 500;
    color: #515c85;
  }
}

.compute_welcome_modal_bg {
  background-size: cover !important;
  background-position: center center !important;
  width: 600px !important;
  border: transparent !important;
  padding: 42px 54px !important;

  .compute__welcome__modal__shadow {
    box-shadow: 0px 2.80141px 11.2057px rgba(20, 20, 43, 0.1) !important;
  }
}

.user__validation__bg {
  background-size: cover !important;
  background-position: center center !important;
  height: 224px;
}

.mainnet_welcome_modal_bg {
  background-size: cover !important;
  background-position: center center !important;
  width: 495px !important;
  height: 400px !important;
  border: transparent !important;
  padding: 15px 17px !important;
  .text_gray {
    color: rgba(207, 217, 226, 0.6);
  }

  .mainnet_tag {
    background: linear-gradient(
      90.31deg,
      #1b9de6 -0.77%,
      #1d80de 56.05%,
      #0751ce 106.18%
    );
  }
  .mainnet_checkout_btn_bg {
    background: linear-gradient(93.36deg, #6bb8ff 40.84%, #4977f9 100.74%);
    transition: background 1s ease-in-out;
    &:hover {
      background: linear-gradient(
        45.31deg,
        #1b9de6 -0.77%,
        #1d80de 56.05%,
        #0751ce 106.18%
      );
    }
  }
  .mainnet_proceed_btn {
    border: 0.5px solid white;
    &:hover {
      color: rgba(207, 217, 226, 0.6);
      border-color: rgba(207, 217, 226, 0.6);
    }
  }
}

.compute_close_icon {
  cursor: pointer;
  span {
    &:hover {
      svg {
        path {
          transition: all 0.3s ease-in-out;
          stroke: white;
        }
      }
    }
  }
}

.remove__action {
  &:hover {
    svg {
      path {
        transition: all 0.3s ease-in-out;
        stroke: var(--action-stroke);
      }
    }
  }
}

.max__height {
  max-height: 35rem;
}

.modal__scroll {
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 6px !important;
    height: 6px !important;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 4px !important;
    background-color: #e4e8f5;
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
}

.storage_tag {
  background: linear-gradient(
    90.31deg,
    #1b9de6 -0.77%,
    #1d80de 56.05%,
    #0751ce 106.18%
  );
}

.add_credit_modal_width {
  width: 450px !important;
}
