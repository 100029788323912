.table__container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid var(--base-border-color);
  box-shadow: 0px 1px 2px rgba(17, 24, 51, 0.04);
  border-radius: 8px;
  margin-top: 30px;
  overflow-x: auto;
  table {
    width: 100%;
    thead {
      tr {
        td {
          width: 100px;
          font-style: normal;
          font-weight: 500;
          font-size: 13px;
          line-height: 17px;
          // color: #838ead;
          height: 50px;
        }
        .header__profile {
          display: flex;
          align-items: center;
        }
      }
    }
    tbody {
      tr {
        .profile__holder {
          display: flex;
          align-items: center;
          width: max-content;
          img {
            height: 34px;
            width: 34px;
            border-radius: 50%;
            margin-right: 15px;
          }
          h5 {
            font-style: normal;
            font-weight: 500;
            font-size: 13px;
            line-height: 20px;
            letter-spacing: -0.2px;
            color: var(--primary-black-text);
          }
        }
        td {
          height: 50px;
        }
        .username {
          h5 {
            font-style: normal;
            font-weight: 500;
            font-size: 13px;
            line-height: 17px;
            padding: 4px auto;
            width: max-content;
            color: var(--base-sub-text-color);
            border: 1px solid var(--base-border-color);
            border-radius: 30px;
            padding: 1px 8px;
          }
        }

        .email {
          font-style: normal;
          font-weight: 500;
          font-size: 13px;
          line-height: 18px;
          color: var(--base-sub-text-color);
        }
        .role {
          .role {
            width: 92px;
            height: 24px;
            border: 1px solid var(--base-border-color);
            border-radius: 30px;
            font-style: normal;
            background: var(--base-bg-color);
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            letter-spacing: -0.2px;

            display: flex;
            align-items: center;
            padding-left: 8px;
          }
        }
        .date {
          font-style: normal;
          font-weight: 500;
          font-size: 13px;
          line-height: 18px;
          color: var(--base-sub-text-color);
        }
      }
    }
  }
}
.table__container__disabled {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid var(--base-border-color);
  box-shadow: 0px 1px 2px rgba(17, 24, 51, 0.04);
  border-radius: 8px;
  margin-top: 30px;
  color: #656f87;
  cursor: not-allowed !important;
  table {
    thead {
      tr {
        td {
          font-style: normal;
          font-weight: 500;
          font-size: 13px;
          line-height: 17px;
          height: 50px;
          input {
            margin-left: 15px;
            margin-right: 25px;
            cursor: pointer;
          }
        }
        .header__profile {
          display: flex;
          align-items: center;
        }
      }
    }
    tbody {
      tr {
        .profile__holder {
          display: flex;
          align-items: center;
          width: max-content;
          input {
            margin-left: 15px;
            margin-right: 25px;
            cursor: pointer;
          }
          img {
            height: 34px;
            width: 34px;
            border-radius: 50%;
            margin-right: 40px;
            filter: grayscale(1) !important;
          }
          h5 {
            font-style: normal;
            font-weight: 500;
            font-size: 13px;
            line-height: 20px;
            letter-spacing: -0.2px;
          }
        }
        td {
          height: 50px;
        }
        .username {
          h5 {
            font-style: normal;
            font-weight: 500;
            font-size: 13px;
            line-height: 17px;
            width: max-content;
            border: 1px solid #e4e8f5;
            border-radius: 30px;
            padding: 1px 8px;
          }
        }
        .username_dark {
          h5 {
            border-color: #bdc6dd !important;
          }
        }
        .email {
          font-style: normal;
          font-weight: 500;
          font-size: 13px;
          line-height: 18px;
        }
        .role {
          .role {
            width: 92px;
            height: 24px;
            border: 1px solid #e4e8f5;
            border-radius: 30px;
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            letter-spacing: -0.2px;
            display: flex;
            align-items: center;
            padding-left: 8px;
          }
        }
        .date {
          font-style: normal;
          font-weight: 500;
          font-size: 13px;
          line-height: 18px;
        }
      }
    }
  }
}
.action {
  span {
    &:hover {
      svg {
        path {
          transition: all 0.3s ease-in-out;
          stroke: var(--action-stroke);
          fill: var(--action-stroke);
        }
      }
    }
  }
}

.delete_action {
  span {
    transition: all 0.3s ease-in-out;
    &:hover {
      cursor: pointer;
      svg {
        transition: all 0.3s ease-in-out;

        stroke: var(--action-stroke);
      }
    }
  }
}
.action__disabled {
  cursor: not-allowed;
  span {
    &:hover {
      cursor: not-allowed;
    }
  }
}
.actionDisabled {
  span {
    &:hover {
      cursor: not-allowed;
    }
  }
}
