.stripe__label {
  color: #667085;
  margin-bottom: 2px;
  font-size: 14px;
  transition: transform 0.5s cubic-bezier(0.19, 1, 0.22, 1),
    opacity 0.5s cubic-bezier(0.19, 1, 0.22, 1);
  padding-top: 8px;
}

.stripe__text__input {
  padding: 6px;
  background-color: #ffffff;
  border-radius: 4px;
  transition: background 0.15s ease, border 0.15s ease, box-shadow 0.15s ease,
    color 0.15s ease;
  border: 1px solid #e6e6e6;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02);
  padding-top: 12px;
  padding-left: 6px;
  padding-bottom: 12px;
  font-size: 16px;
  width: 100%;
  height: 44.3984px;
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  text-transform: none;
  text-decoration: none solid rgb(223, 27, 65);
  letter-spacing: normal;
  word-spacing: 0px;
  line-height: 18.4px;
  color: #667085;
  &:focus {
    outline: 0 !important;
    border-color: rgba(5, 115, 225, 0.5) !important;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02),
      0 0 0 3px rgba(5, 115, 225, 0.25), 0 1px 1px 0 rgba(0, 0, 0, 0.08) !important;
  }
  &::placeholder {
    color: #74777c;
  }
}
