@media (max-width: 1024px) {
  .screen__width {
    padding-left: 20px !important;
  }
}
.screen__width {
  width: 95%;
  height: 100%;
  min-height: calc(100vh - 168px);
  max-width: 1400px;
  // padding-top: 100px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2rem;
  @media (max-width: 1024px) {
    .screen__inner__con {
      grid-template-columns: none !important;
    }
  }
  .screen__inner__con {
    display: grid;
    grid-template-columns: repeat(12, minmax(0, 1fr));
    gap: 1.5rem;
    @media (max-width: 1024px) {
      .sidebar {
        display: none;
      }
    }
    .sidebar {
      grid-column: span 2 / span 3;
    }
    @media (max-width: 1024px) {
      .right__con {
        width: 95% !important;
        margin-left: auto !important;
        margin-right: auto !important;
        grid-column: auto !important;
        border-left-width: 0px !important;
        padding-left: 0.5rem !important;
        margin-top: 0rem !important;
      }
    }
    .right__con {
      grid-column: span 10 / span 10;
      margin-top: 3.5rem;
      border-left-width: 1px;
      border-color: var(--base-border-color);
      --tw-border-opacity: 1;
      padding-left: 1.5rem;
    }
  }
}

.org__loading__steps:before {
  border-left: 2px solid var(--primary-black-text);
  content: '';
  width: 2px;
  height: 296px;
  position: absolute;
  left: 9px;
  top: 20px;
  opacity: 0.1;
}

.screen__width__invite {
  width: 95%;
  height: 100vh;
  max-width: 1400px;
  padding-top: 100px;
  margin-left: auto;
  margin-right: auto;
}

.error__fallback {
  height: 100vh;
}

.ignore__margin__top {
  margin-top: 0 !important;
}

.responsive__view {
  position: fixed;
  overflow: hidden;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  // background-color: rgba(255, 255, 255, 0.9);
  z-index: 1000;
  margin: 0;
  padding: 0;
}

// Spinner
.ring__spinner {
  display: inline-block;
  width: 18px;
  height: 18px;
  border: 2px solid var(--base-border-color);
  border-radius: 50%;
  border-top-color: var(--action-primary-default);
  animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.screen__width__content {
  width: 95%;
  height: 100%;
  max-width: 1400px;
  margin-top: 30px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2rem;
}

.service__card__disabled {
  div {
    cursor: not-allowed !important;
  }
}

.inner__tabs {
  position: sticky;
  // top: 110px;
  top: 72px;
}

// TODO - REMOVED - USED IN PROJECT/STORAGE

.alt__org__dp {
  border-radius: 50%;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 20px;
  font-weight: bold;
  background-color: #4978f96c;
}

.profile__pic {
  height: 40px;
  width: 40px;
  cursor: pointer;
}

.profile__pic:hover {
  animation: pulse 2s infinite;
  box-shadow: 0 0 0 #4183c456;
}

@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 #4978f949;
    box-shadow: 0 0 0 0 #4978f942;
  }
  70% {
    -moz-box-shadow: 0 0 0 3px #0e48e73f;
    box-shadow: 0 0 0 3px #0e48e73f;
  }
  100% {
    -moz-box-shadow: 0 0 0 0 #4978f959;
    box-shadow: 0 0 0 0 #4978f95e;
  }
}

.page__not__found {
  position: relative;
  min-height: calc(100vh - 168px);
  .page__not__found__sub {
    z-index: 777;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    h1 {
      font-size: 2rem;
      font-weight: bold;
    }
    p {
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 18px;
      color: #424d76;
      text-align: center;
      margin: 15px 0px 20px 0px;
    }
    .community__page__not__found__sub {
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 18px;
      color: #424d76;
      text-align: center;
      margin: 15px 0px 20px 0px;
    }
  }
}

.search__bar {
  height: 42px;
  max-width: 287px;
  display: flex;
  align-items: center;
  // justify-content: center;
  background: var(--base-bg-color);
  border: 1px solid var(--base-border-color);
  border-radius: 8px;
  padding-right: 12px;
  input {
    height: min-content;
    width: 100%;
    line-height: 0px;
    font-size: 13px;
    font-weight: 500;
    // color: #838ead;
  }
  input::placeholder {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: #3d4155;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  input:focus {
    outline: none;
  }
  img {
    width: 15px;
    height: 15px;
    margin: 0px 12px;
  }
}

.search__bar.expanded__dropdown {
  max-width: 98% !important;
  margin: auto;
  position: sticky;
  top: 16px;
  padding-top: 16px;
  background: var(--base-bg-color) !important;
  input {
    background: var(--base-bg-color) !important;
  }
}

.search__bar:not(.expanded__dropdown):focus-within {
  border-color: var(--base-border-color);
  box-shadow: 0px 0px 4px 2px #4978f95e;
}

.log__search__bar__con {
  max-width: 1000px !important;
  width: 70% !important;
  background-color: var(--base-bg-color);
}

.search__bar__disabled {
  cursor: not-allowed;
  // background: #efefef !important;
}

.log__search__bar {
  height: 100% !important;
}

.deploy__new__search__bar {
  height: 44px;
  max-width: none;
}

.nav__outer_con {
  border: 1px solid var(--base-border-color);
  width: 100%;
  height: 73px;
  // display: flex;
  // align-items: center;
  // justify-content: center;
  // position: sticky;
  // left: 0;
  // margin-bottom: 100px;
  z-index: 999;
  background: var(--base-bg-color);
  // -webkit-backdrop-filter: blur(5px);
  // backdrop-filter: blur(5px);
  @-moz-document url-prefix() {
    background: white;
  }
  .nav__inner__con {
    width: 95%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    max-width: 1400px;
    .spheron__logo {
      height: 31px;
    }
  }

  .org__name__wrapper {
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    color: var(--primary-gray-text);
    height: 40px;
  }

  .nav__item__con {
    display: flex;
    align-items: center;
  }

  .nav__item {
    transition: 1s all;
    .nav_link {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0px 0px 0px 25px !important;
      span {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: -0.2px;
        color: var(--action-ghost-text);
        padding: 5px 8px 5px 8px;
      }
      path {
        stroke: var(--action-ghost-text);
      }
    }

    // .nav_link:hover {
    //   background-color: #f4f7ff;
    //   padding: 5px 5px 5px 5px;
    //   border-radius: 5px;
    //   span {
    //     color: var(--action-primary-default);
    //   }
    //   .nav__icon > path {
    //     stroke: var(--action-primary-default);
    //   }
    // }
    .nav_link:hover {
      position: relative;

      &::after {
        content: '';
        position: absolute;

        width: 120%;
        height: 120%;
        padding: 5px;
        z-index: -55;
        border-radius: 5px;
        background-color: var(--logo-cards-rows-etc-hover);
      }
    }

    .active__link {
      padding: 5px 8px 5px 8px;
      background-color: var(--logo-cards-rows-etc-hover);
      border-radius: 5px;

      &:hover {
        &::after {
          content: none;
        }
      }
      // span {
      //   color: var(--action-primary-default);
      // }
      // .nav__icon > path {
      //   stroke: var(--action-primary-default);
      // }
    }
  }
}

.projects__wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .all__projects__con {
    width: 95%;
    max-width: 1400px;
    min-height: calc(70vh - 168px);
    span {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      color: #838ead;
    }
    .sort__project {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      color: #838ead;
      display: flex;
      align-items: center;
      z-index: 1;
    }
    .cards__container {
      margin-top: 2rem;
      margin-bottom: 2rem;
      max-width: 1400 px;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      justify-items: center;
      gap: var(--spacing-6);
    }
    @media (max-width: 1024px) {
      .cards__container {
        margin-top: 16px;
        margin-bottom: 16px;
        max-width: 1400 px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        justify-items: center;
        gap: 1rem;
      }
    }
    @media (max-width: 720px) {
      .cards__container {
        margin-top: 16px;
        margin-bottom: 16px;
        max-width: 1400 px;
        display: grid;
        grid-template-columns: 1fr;
        justify-items: center;
        gap: 1rem;
      }
    }
  }
}

.project__card {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: var(--base-bg-color);
  border: 1px solid var(--base-border-color);
  box-shadow: var(--card-dropshadow);
  border-radius: 8px;
  margin-bottom: 20px;
  padding: 16px 16px 16px 30px;
  cursor: pointer;
  .project__card__site__live {
    display: flex;
    margin-top: 16px;
    align-items: center;
    justify-content: flex-start;
    flex-grow: 1;
    div {
      margin-left: 8px;
      .site__live {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 14px;
        color: var(--primary-gray-text);
      }
    }
    img {
      height: 20px;
      width: 20px;
    }
  }

  .last__updated {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #959cae;
    margin-top: 16px;
    padding-bottom: 16px;
    border-bottom: 1px solid var(--base-border-color);
  }
  .github__link {
    display: flex;
    align-items: center;
    margin-top: 16px;
    img {
      height: 25px;
      width: 25px;
      margin-right: 13px;
    }
    span {
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 16px;
      color: var(--primary-gray-text);
    }
  }
}

.project__card:hover {
  background-color: var(--logo-cards-rows-etc-hover);
  --tw-shadow: var(--tw-card-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  transition-duration: 300ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.project__card__loading {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: var(--base-bg-color);
  border: 1px solid var(--base-border-color);
  box-shadow: 0px 1px 2px rgba(17, 24, 51, 0.04);
  border-radius: 8px;
  margin-bottom: 20px;
  padding: 16px 16px 16px 30px;
  .project__card__site__live {
    display: flex;
    margin-top: 16px;
    align-items: center;
    justify-content: flex-start;
    flex-grow: 1;
    div {
      margin-left: 8px;
      .site__live {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 14px;
        color: var(--primary-gray-text);
      }
    }
    img {
      height: 20px;
      width: 20px;
    }
  }

  .last__updated {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #959cae;
    margin-top: 16px;
    padding-bottom: 16px;
    border-bottom: 1px solid var(--base-border-color);
  }
  .github__link {
    display: flex;
    align-items: center;
    margin-top: 16px;
    img {
      height: 25px;
      width: 25px;
      margin-right: 13px;
    }
    span {
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 16px;
      color: var(--primary-black-text);
    }
  }
}

.site__link {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  cursor: pointer;
  text-decoration: none;
}

.configure__site__link {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #4977f9;
  cursor: pointer;
  text-decoration: none;
  display: flex;
  align-items: center;
}

.project__name {
  font-size: 13px;
  color: var(--base-sub-text-color);
  font-weight: 600;
  margin-left: 8px;
  overflow: hidden;
  width: 142px;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0px 4px;
}

.nav__fix {
  padding-top: 100px;
}

::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}

.deployment__card {
  .bg__deployed {
    background: var(--feedback-success-bg);
    border: 1px solid var(--base-border-color);
    box-shadow: 0px 1px 2px rgba(17, 24, 51, 0.04);
    border-radius: 8px;
  }
  .bg__failed {
    background: var(--feedback-error-bg);

    box-shadow: 0px 1px 2px rgba(17, 24, 51, 0.04);
    border-radius: 8px;
    .na__text {
      color: var(--feedback-error-text);
    }
  }
  .bg__queued {
    background: var(--feedback-default-bg);
    border: 1px solid var(--base-border-color);
    box-shadow: 0px 1px 2px rgba(17, 24, 51, 0.04);
    border-radius: 8px;
    .na__text {
      color: #ffa215;
    }
  }
  .bg__running {
    background: var(--feedback-default-bg);
    border: 1px solid var(--base-border-color);
    box-shadow: 0px 1px 2px rgba(17, 24, 51, 0.04);
    border-radius: 8px;
    .na__text {
      color: #ffa215;
    }
  }
  .bg__authorization {
    background: var(--feedback-info-bg);
    border: 1px solid var(--base-border-color);
    box-shadow: 0px 1px 2px rgba(17, 24, 51, 0.04);
    border-radius: 8px;
    .na__text {
      color: #ffa215;
    }
  }
  .bg__killing {
    background: var(--feedback-error-bg);
    border: 1px solid var(--base-border-color);
    box-shadow: 0px 1px 2px rgba(17, 24, 51, 0.04);
    border-radius: 8px;
    .na__text {
      color: var(--feedback-error-text);
    }
  }
  .bg__canceled {
    background: var(--feedback-error-bg);
    border: 1px solid var(--base-border-color);
    box-shadow: 0px 1px 2px rgba(17, 24, 51, 0.04);
    border-radius: 8px;
    .na__text {
      color: var(--feedback-error-text);
    }
  }
}

.deployment__card__table {
  width: 100%;
  tr {
    td {
      padding: 6px;
    }
  }
}

.divider {
  border-left: 1px solid grey;
  height: 10px;
  margin-left: 4px;
}

.dark_boxShadow {
  box-shadow: var(--global-dropshadow);
}

.letter_spacing {
  letter-spacing: 1.05px;
}

.project_meta_card {
  max-width: 90vw !important;
}

.domain_card {
  max-width: 90vw !important;
}

.icon_hover {
  span {
    &:hover {
      svg {
        path {
          transition: all 0.3s ease-in-out;
          stroke: var(--action-stroke);
        }
      }
    }
  }
}

.bubble__box__min__height {
  min-height: 15rem;
}

.active__input__focus:focus {
  box-shadow: 0px 0px 0px 4px var(--input__shadow__focus);
}

.focus__box__shadow {
  box-shadow: 0px 0px 0px 4px var(--input__shadow__focus);
}
