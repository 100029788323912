.check__icon {
  rect {
    fill: #0057ff;
  }
}

.search__icon {
  path {
    stroke: #667085;
  }
}

.arrow__icon {
  path {
    stroke: #3d4155;
  }
}

.search__input:focus-visible {
  outline: 1px solid #3379ff;
}

.search__input__error:focus-visible {
  outline: 1px solid transparent;
}

.search__input__error::placeholder {
  color: #d1d4da !important;
  opacity: 1;
}

.search__input::placeholder {
  color: #d1d4da !important;
  opacity: 1;
}
.info__icon {
  path {
    stroke: #3d4155;
  }
}

.dropdown__icon {
  path {
    stroke: #667085;
  }
}

.check__dropdown__icon {
  path {
    stroke: #0057ff;
  }
}

.toggle__switch input[type='checkbox'] {
  opacity: 0;
  width: 0;
  height: 0;
}

.toggle__switch label {
  border-radius: 34px;
  border: 1px solid var(--form-border);
  transition: background-color 0.4s;
}

.toggle__switch label:before {
  position: absolute;
  content: '';
  width: 16px;
  height: 16px;
  left: 2px;
  bottom: 1px;
  background-color: var(--form-placeholder);
  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
    0px 1px 2px rgba(16, 24, 40, 0.06);
  border-radius: 50%;
  transition: transform 0.4s;
}

.toggle__switch input:checked + label {
  background-color: var(--form-selected);
}

.toggle__switch input:checked + label:before {
  transform: translateX(15.5px);
  background-color: var(--form-selected-color);
}

.custom__input:focus-visible {
  outline: none;
  border: 1px solid #669aff;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
}

.cross__icon {
  path {
    fill: #667085;
  }
}

.rotate180 {
  transform: rotate(180deg);
}
.rotate0 {
  transform: rotate(0deg);
}

.tooltip {
  position: fixed;
  display: none;
  background-color: #000;
  color: #fff;
  padding: 5px;
  border-radius: 4px;
}

.container {
  position: relative;
  width: 200px;
  height: 200px;
  background-color: #f0f0f0;
  margin-top: 50px;
  padding: 20px;
}

.active__input__focus:focus {
  box-shadow: 0px 0px 0px 4px #e5eeff;
}

.custom__title__gradient {
  background: linear-gradient(to bottom right, #9f3bdf, #4b1db5, #65ccf3);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}
