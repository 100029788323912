.light__blue__bg {
  height: 100vh;
  width: 100vw;
}

.contact {
  color: #4977f9;
  font-size: 15px;
  margin-right: 40px !important;
  font-weight: 500;
  cursor: pointer;
}

.button {
  height: 40px;
  width: 100px;
  font-size: 14px;
  padding: 0px;
  font-weight: 600;
  background-color: transparent;
}

@media (max-width: 768px) {
  .loginCenterCard {
    padding: 2.5rem 1rem !important;
    background: transparent !important;
    box-shadow: none !important;
    border-radius: 0px;
  }
}

.loginCenterCard {
  height: auto;
  padding: 2.5rem 4rem;
  background: #ffffff;
  box-shadow: 0px 8px 20px -6px rgba(15, 27, 56, 0.08);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  width: 100%;
}

.big__text {
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 52px;
  letter-spacing: -1px;
  text-align: center;
  color: #0f1b38;
}

.sub__text {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #656f87;
}

.already {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #0f1b38;
}

.navbar {
  max-width: 1000px;
  width: 80%;
  display: flex;
  margin: 30px 0px;
  align-items: center;
  justify-content: space-between;
}

.nav__cli__outer__con {
  border-bottom: 1px solid var(--base-border-color);
  width: 100%;
  height: 78px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  background-color: #ffffff;
  // margin-bottom: 100px;
  z-index: 999;
  left: 0;
  background: var(--navbar-bg);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  @-moz-document url-prefix() {
    background: white;
  }
  .nav__cli__inner__con {
    width: 95%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    max-width: 1400px;
    .spheron__logo {
      height: 31px;
    }
  }
}

.navbar__cli {
  border: 1px solid var(--base-border-color);
  max-width: 1400px;
  height: 78px;
  width: 95%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.gradient {
  background: var(
    --Gradiant-1,
    linear-gradient(
      48deg,
      var(--base-bg-color) 24.79%,
      var(--base-heading-text-color) 82.89%
    )
  );
  // box-shadow: 214px 214px 214px;
  border-radius: 9999px;
  filter: blur(107px);
  width: 30rem;
  height: 28rem;
  right: -1rem;
  top: -6rem;
}

.user__gradient {
  width: 9rem;
  height: 9rem;
  background: linear-gradient(101deg, #0057ff 0%, #8247ff 100%, #8247ff 100%);
}
