* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
* {
  .slick-prev,
  .slick-next {
    font-size: 15px !important;
  }

  .slick-prev:before,
  .slick-next:before {
    content: '' !important;
  }

  .slick-dots-pricing {
    position: absolute;
    margin-top: 1.5rem;
    display: block;
    width: 100%;
    padding: 0;
    list-style: none;
    text-align: center;
  }

  .slick-dots-pricing.slick-thumb {
    display: flex !important;
    flex-direction: row;
    justify-content: center;
    cursor: pointer;
  }

  .dark .slick-dots-pricing.slick-thumb li {
    width: 10px;
    height: 10px;
    margin: 0 3px;
    border-radius: 999px;
    transition: all 0.5s ease-in-out;
    will-change: width;
    background: #747481;
    text-indent: -9999px;
  }

  .slick-dots-pricing.slick-thumb li {
    width: 10px;
    height: 10px;
    margin: 0 3px;
    border-radius: 999px;
    transition: all 0.5s ease-in-out;
    will-change: width;
    background: var(--base-heading-text-color);
    text-indent: -9999px;
  }

  .slick-dots-pricing.slick-thumb li.slick-active {
    background: var(--base-heading-text-color);
    width: 24px;
  }
}
.wallet-info-bg {
  background: radial-gradient(
    188.36% 168.95% at 97.57% 0%,
    #ffffff 0%,
    #f9fafe 32.51%,
    #eaeefc 98.1%
  );
}
.deploy_blue {
  background: var(--feedback-success-bg);
}
.pending_yellow {
  background: var(--feedback-warning-bg);
}
.failed_red {
  background: var(--feedback-error-bg);
}
.awaiting_auth_yellow {
  background: var(--feedback-warning-bg);
}

.deploy_queued {
  background: var(--feedback-default-bg);
}

* {
  .slick-prev:before,
  .slick-next:before {
    font-size: 32px !important;
  }

  .slick-arrow {
    path {
      visibility: hidden;
    }
  }

  .slick-dots.slick-thumb {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .slick-dots.slick-thumb li {
    width: 10px;
    height: 10px;
    margin: 0 3px;
    border-radius: 999px;
    transition: all 0.5s ease-in-out;
    will-change: width;
    background: #dee9ff;
    border: 1px solid var(--base-heading-text-color);
    text-indent: -9999px;
  }

  .slick-dots.slick-thumb li.slick-active {
    background: var(--base-heading-text-color);
    width: 24px;
  }
}

.monaco-editor {
  .margin {
    .margin-view-overlays {
      background-color: var(--monaco-bg) !important;
    }
    .line-numbers {
      background-color: var(--monaco-bg) !important;
    }
  }
}
.monaco__editor__custom {
  div.monaco-scrollable-element.editor-scrollable.vs
    > div.lines-content.monaco-editor-background {
    background-color: var(--monaco-bg) !important;
    color: white !important;
  }
}
