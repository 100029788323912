.referral__credits__container {
  border-radius: 8px;
  background: var(
    --gradient-blue-sky,
    linear-gradient(135deg, #0057ff 0%, #01abff 100%)
  );
  box-shadow: 0px 2px 20px 0px rgba(0, 40, 118, 0.12);
}

.refer__container {
  border-radius: 12px;
  background: var(--referral__card__gradient);
}
