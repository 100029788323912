.loader__screen {
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: 'Inter', sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 1rem;
  line-height: 22px;
  color: #1b62c5;
  gap: 1rem;
}
.loader__screen__logo {
  animation-name: splash;
  animation-duration: 4s;
  animation-iteration-count: infinite;
}
@keyframes splash {
  0% {
    transform: scale(0.75);
    opacity: 0.7;
  }
  50% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(0.75);
    opacity: 0.7;
  }
}
.loader__screen__text {
  animation-name: splashText;
  animation-duration: 4s;
}
@keyframes splashText {
  0% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}
